const jobs = [
  {
    id: 0,
    role: 'Software Developer - Intern',
    place: 'Thoughtworks',
    website: 'https://www.thoughtworks.com/en-au/about-us',
    startDate: 'December 2023',
    endDate: 'Feburary 2024',
    description: [
      'Communicated and collaborated in daily meetings to update work progress and determine short term goals.',
      'Wrote and reviewed over 10 key ISO 27001 Information security, cybersecurity and privacy protection documents.',
      "Demonstrated expertise in analysing security requirements and drafting clear, concise guides, contributing to Thoughtworks' certification goal.",
      'Performed database refactoring to merge 2 different regional databases.',
      'Modified the frontend of the application to accommodate new database changes.',
    ],
  },
  {
    id: 1,
    role: 'Software Developer - Intern',
    place: 'Thoughtworks',
    website: 'https://www.thoughtworks.com/en-au/about-us',
    startDate: 'November 2022',
    endDate: 'Feburary 2023',
    description: [
      'Developed and maintained Thoughtworks internal project s frontend, collaborated with the team to build a visually appealing and user friendly interface.',
      'Wrote comprehensive unit tests using Jest library for various components which helped to identify and resolve bugs early in development stage and maintained high quality code.',
      'Implemented validations and security measures for API endpoints to protect sensitive data and safeguard the system against cyber attacks such as SQL injection and DDoS attacks.',
      'Created user guides and documentation to assist end users in understanding the functionalities of the implemented features.',
    ],
  },
  {
    id: 2,
    role: 'Machine Operator',
    place: 'RROA',
    website: 'https://www.coles.com.au/about',
    startDate: 'November 2018',
    endDate: 'December 2021',
    description: [
      'Monitoring equipment with regular visual checks and isolating machinery in the event of mechanical or process issues',
      'Ensuring compliance with all safety requirements and adhering to all quality control procedures',
    ],
  },
  {
    id: 3,
    role: 'Retail Assistance',
    place: 'Target',
    website: 'https://www.target.com.au/company/about-us',
    startDate: 'March 2017',
    endDate: 'November 2018',
    description: [
      'Provided assistance to self-checkout customers and resolved customer issues',
      'Stocking the shelves and displayed merchandise to maximize sales of the latest items',
      'Ensuring maximum sales by delivering the highest standards of customer service',
    ],
  },
];

export default jobs;
