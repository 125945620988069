import React from 'react';
import '../css/Job.css';

function Job({ job }) {
  return (
    <div className="job-section">
      <div className="job-title-div">
        {/* <p className="job-title">
          <span className="job-role">{job.role}</span>
          <span className="job-at">@</span>
          <span className="job-place anchor-effect">{job.place}</span>
        </p> */}
        <p className="job-title">
          <span className="job-role">{job.role}</span>
          <span className="job-at">@</span>
          <a
            className="job-place anchor-effect"
            target="_blank"
            rel="noreferrer"
            href={job.website}
          >
            {job.place}
          </a>
        </p>
        <p className="job-duration">
          {job.startDate} - {job.endDate}
        </p>
      </div>
      <ol className="job-ol">
        {job.description.map((description, i) => (
          <li className="job-description" key={i}>
            {description}
          </li>
        ))}
      </ol>
    </div>
  );
}

export default Job;
