import React from 'react';
import '../css/About.css';
import photo from '../img/himself.webp';

function About() {
  return (
    <div className="container" id="about-section">
      <div className="section-title">
        <p>
          <span className="section-title-number">01.</span>
          <span className="section-title-body">About me</span>
        </p>
        <div className="horizontal-line"></div>
      </div>

      <div className="about-div">
        <div className="about-left">
          <p className="description">
            Graduate software engineer with a passion for using tech to solve
            problems. I have a solid foundation in JavaScript and Python, as
            well as coursework in data structures, algorithms, and UI/UX. As a
            team player, I've worked on group projects and contributed
            problem-solving and communication skills. My goal is to apply my
            skills to real-world projects and make a positive impact as a
            software engineer.
          </p>

          <p>Here are a few technologies I’ve been working with recently:</p>

          <div className="about-skills">
            <ol>
              <li>HTML</li>
              <li>CSS</li>
              <li>JavaScript</li>
              <li>React-JS</li>
            </ol>

            <ol>
              <li>Express-JS</li>
              <li>MongoDB</li>
              <li>Bootstrap</li>
              <li>TailwindCSS</li>
            </ol>
          </div>
        </div>

        <div className="about-right">
          <img
            src={photo}
            alt="developer"
            className="developer"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
}

export default About;
